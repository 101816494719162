// 用户管理
import  request  from "@/utils/request";

//受检档案列表
    // 受检人档案列表信息
    export function getUserList (parameter){
        return request({
        url:'/api/report-info/getReportInfoList',
        method:'post',
        data:parameter
    })
    }

    // 受检人档案详情列表--table
    export function getRecordList (parameter){
        return request({
        url:'/api/report-info/getReportRecordList',
        method:'post',
        data:parameter
    })
    }

    //受检人基本信息
    export function getBaseInfo (parameter){
        return request({
        url:'/api/report-analysis/viewReportInfo',
        method:'post',
        data:parameter
    })
    }

    //查看报告详情
    export function getReportInfo(parameter){
        return request({
            url:'/api/report-info/viewReportData',
            method:'post',
            data:parameter
        })
    }


//受检评估模块

    //获取脊柱初筛列表
    export function getChafferList (parameter){
        return request({
        url:'/api/report-analysis/getSpineReportList',
        method:'post',
        data:parameter
    })
    }

    //获取专业脊柱评估列表
    export function getMajorList (parameter){
        return request({
        url:'/api/report-analysis/getReportList',
        method:'post',
        data:parameter
    })
    }

    //获取X光检测列表
    export function getXrayList (parameter){
        return request({
        url:'/api/report-analysis/getXraysReportList',
        method:'post',
        data:parameter
    })
    }

    //获取受检用户档案列表--单条数据
    export function getReportListOne (parameter){
        return request({
        url:'/api/report-info/getReportList',
        method:'post',
        data:parameter
    })
    }

    //档案信息详情  
    export function viewReportData (parameter){
        return request({
        url:'/api/report-analysis/viewReportData',
        method:'post',
        data:parameter
    })
    }
    //档案信息详情 -下载 原始数据--AI筛查原始数据   
    export function downloadOrgData (parameter){
        return request({
        headers: {
            'content-type': 'application/json;charset=utf-8',
            },
        responseType: 'blob',
        url:'/api/report-analysis/downReport',
        method:'post',
        data:parameter
    })
    }

    //重新上传报告  
    export function uploadReportImage (parameter){
        return request({
            headers:{'Content-Type': 'multipart/form-data'},
            url:'/api/report-analysis/uploadReportImage',
            method:'post',
            data:parameter
    })
    }

    //pc后台发送消息订阅报告 
    export function sendReport (parameter){
        return request({
        url:'/api/report-analysis/sendReport',
        method:'post',
        data:parameter
    })
    }

    //pc后台发送失败消息订阅报告 
    export function sendFailReport (parameter){
        return request({
        url:'/api/report-analysis/sendFailReport',
        method:'post',
        data:parameter
    })
    }

     //pc后台发送成功短信通知
     export function sendSMS (parameter){
        return request({
        url:'/api/report-analysis/sendSuccessSmsReport',
        method:'post',
        data:parameter
    })
    }

    //pc后台发送失败短信通知
    export function nosendSMS (parameter){
        return request({
        url:'/api/report-analysis/sendFailSmsReport',
        method:'post',
        data:parameter
    })
    }

    //更改受检者基本信息
    export function updateReportInfo (parameter){
        return request({
        url:'/api/report-info/updateReportInfo',
        method:'post',
        data:parameter
    })
    }

    //批量下载原始数据
    export function downloadFileAll (parameter){
        return request({
            headers: {
                'content-type': 'application/json;charset=utf-8',
                },
            responseType: 'blob',
            url:'/api/report-analysis/batchDownloadReport',
            method:'post',
            data:parameter
        })
    }
    
    //重新计算背部图
    export function getNewBackImg(parameter){
        return request({
        url:'/api/report-analysis/recalculateBackHalfImage',
        method:'post',
        data:parameter
        })
    }

     //更新算法计算结果
     export function updateResult(parameter){
        return request({
        url:'/api/report-analysis/updateReportResult',
        method:'post',
        data:parameter
        })
    }

     //批量下载原始数据2
     export function downloadBigFile (parameter){
        return request({
            headers: {
                'content-type': 'application/json;charset=utf-8',
                },
            responseType: 'blob',
            url:'/api/report-analysis/batchDownloadAllTypeReport',
            method:'post',
            data:parameter
        })
    }

    //重新计算背部图
    export function deleteOriImg(parameter){
        return request({
        url:'/api/report-analysis/batchDeleteOriginalImage',
        method:'post',
        data:parameter
        })
    }


    //更新x光标记图  /api/report-analysis/updateXraysCobbAngle
    export function updateXMark (parameter){
        return request({
        url:'/api/report-analysis/updateXraysCobbAngle',
        method:'post',
        data:parameter
    })
    }

    //重新计算X光结果
    export function updateXResult (parameter){
        return request({
        url:'/api/report-analysis/recalculateXraysImage',
        method:'post',
        data:parameter
    })
    }

    //更新cobb角的计算记过
    export function updateCobbAngle (parameter){
        return request({
        url:'/api/report-analysis/updateXraysCobbAngle',
        method:'post',
        data:parameter
    })
    }
    ///api/report-analysis/updateXraysCobbAngle


    //pc后台更新专家报告解析
    export function sendAnalysisReport (parameter){
      return request({
      url:'/api/report-analysis/updateReportExpertAnalysisResult',
      method:'post',
      data:parameter
    })
  }