<!-- 此页面差那后台数据，改table字段，根据接口写方法 -->
<template>
    <div class="detail" ref="detail">
        <!-- top -->
      <el-breadcrumb separator="/">
            <el-breadcrumb-item >受检管理</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="back4">检测任务</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="back3">任务详情</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="back2">任务明细</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="back1">单条任务列表</el-breadcrumb-item>
            <el-breadcrumb-item>报告详情</el-breadcrumb-item>
      </el-breadcrumb>
        <div class="detailBox" v-if="pageData">
            <div class="topBoxdb">
                <div class="downBox">
                    <div class="down" @click="downloadReport">下载个人报告</div>
                    <div class="down" style="margin-left:16px" @click="deletee">删除</div>
                </div>
                <div class="infoBox">
                    <span class="blueTitle" style="margin-left:12px;">受检基本信息</span>
                    <el-descriptions style="margin-top:40px">
                        <el-descriptions-item label="姓名">{{pageData.examineName}}</el-descriptions-item>
                        <el-descriptions-item label="性别">{{pageData.gender}}</el-descriptions-item>
                        <el-descriptions-item label="出生年月" v-if="topData">{{topData.birthday}}</el-descriptions-item>
                        <el-descriptions-item label="身份证" v-if="topData">{{topData.cardId}}</el-descriptions-item>
                        <el-descriptions-item label="检测时间">{{pageData.examineTime}}</el-descriptions-item>
                        <el-descriptions-item label="受检机构" v-if="pageData.orgType =='受检机构'">{{pageData.examinedOrgName}}</el-descriptions-item>
                        <el-descriptions-item label="采样机构" v-if="pageData.orgType =='采样机构'">{{pageData.examinedOrgName}}</el-descriptions-item>
                        <el-descriptions-item label="采样点" v-if="pageData.orgType =='采样点'">{{pageData.examinedOrgName}}</el-descriptions-item>
                        <el-descriptions-item label="检测机构">{{pageData.orgName}}</el-descriptions-item>
                    </el-descriptions>
                </div>
            </div>
            <!-- 体态数据 -->
                <div class="imgBox" v-if="(pageData.artPic!=null) || (pageData.backVectorImage!=null) || (pageData.vectorPic!=null)">
                        <span class="blueTitle" style="margin-left:12px;">体态图像</span>
                        <div class="centerImg">
                            <div class="imgb" v-if="pageData.artPic!=null">
                            <viewer :images="[pageData.artPic]">
                                    <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                                    <img alt="" class="imgs" :src="pageData.artPic">
                                </el-tooltip>
                            </viewer>
                                <span class="title">ATR角度</span>
                            </div>
                          <div class="imgb" v-if="pageData.backMuscleImage!=null">
                                <viewer :images="[pageData.backMuscleImage]">
                                    <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                                        <img alt="" class="imgs" :src="pageData.backMuscleImage">
                                    </el-tooltip>
                                </viewer>
                                <span class="title">肌肉图</span>
                            </div>
                            <div class="imgb" v-if="pageData.vectorPic!=null">
                                <viewer :images="[pageData.vectorPic]">
                                    <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                                        <img alt="" class="imgs" :src="pageData.vectorPic">
                                    </el-tooltip>
                                </viewer>
                                <span class="title">侧面图</span>
                            </div>
                        </div>
                    <!-- dataResoultBox -->
                    <div class="dataResoult">
                        <span class="blueTitle" style="margin-left:12px;">体态数据结果</span>
                        <!-- table -->
                        <table border="1" style='width:100%;margin-top:20px;margin-bottom:8px;' cellspacing="0" cellpadding="24">
                                <tr style="background-color:#00CCCC;color:#fff;font-size:16px;border:1px solid #00CCCC;text-align:center">
                                    <td>项目</td>
                                    <td>胸椎生理曲线（驼背角度）</td>
                                    <td> 腰椎生理曲线</td>
                                    <td> 躯干旋转角度 ATR</td>
                                    <td> Cobb 角</td> 
                                </tr>
                                <tr style="border:1px solid #AEAEAE">
                                    <td class="tdStyle">测量值</td>
                                    <td class="tdStyle">{{pageData.chestAngleExamineValue}}</td>
                                    <td class="tdStyle">{{pageData.waistAngleExamineValue}}</td>
                                    <td class="tdStyle">{{pageData.spineCurveAngleExamineValue}}</td>
                                    <td class="tdStyle">{{pageData.cobbAngle}}</td>
                                </tr>
                                <tr style="border:1px solid #AEAEAE;">
                                    <td class="tdStyle">标准范围</td>
                                    <td class="tdStyle">10-40°</td>
                                    <td class="tdStyle">20-45°</td>
                                    <td class="tdStyle">0-5°</td>
                                    <td class="tdStyle">0-10°</td>
                                </tr>
                            </table>
                            <span class="tip"><span style="color:red;">*</span>脊柱检测数据基于客观测量数据，被测者不正确站姿可能引发误差，特殊情况请咨询专业人员</span>
                    </div>
                </div>
            <!-- 足部数据 -->
            <div class="footData">
                <div class="footLeft" v-if="pageData.footImage!=null">
                    <span class="blueTitle" style="margin-left:12px;">足部图像</span>
                    <viewer :images="[pageData.footImage]" >
                        <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                            <img alt="" class="footImage" :src="pageData.footImage">
                        </el-tooltip>
                    </viewer>
                </div>
                <div class="footRight" v-if="pageData.footResult!=null">
                    <span class="blueTitle" style="margin-left:12px;">足部结果</span>
                    <div class="footResult">
                          <el-checkbox v-model="pageData.footResult == '正常'" disabled>正常</el-checkbox>
                          <el-checkbox v-model="pageData.footResult == '扁平足'" disabled>扁平足</el-checkbox>
                          <el-checkbox v-model="pageData.footResult == '高弓足'" disabled>高弓足</el-checkbox>
                          <el-checkbox v-model="pageData.footResult == '先天畸形'" disabled>先天畸形</el-checkbox>
                          <el-checkbox v-model="pageData.footResult == '其他异常'" disabled>其他异常</el-checkbox>
                    </div>
                </div>
            </div>
            <!-- evaluationBox -->
            <!-- 非初筛版报告 -->
            <div class="evaluationBox" v-if="pageData.reportType!='小程序脊柱初筛'">
                <span class="blueTitle" style="margin-left:12px;" v-if="pageData.examineResult!=null || pageData.footResult!=null">体态评估结果</span>
                <div class="resoult" v-if="pageData.examineResult!=null">
                    <p class="resoltInfo" v-if="pageData.spineSuggestion!=null">{{pageData.examineResultPrefix }}
                        <span v-if="pageData.examineResult=='正常'" style="color:#00CCCC;font-size:20px">{{pageData.examineResult}}</span>
                        <span v-if="pageData.examineResult !='正常'" style="color:#FC5555;font-size:20px">{{pageData.examineResult}}</span>
                        {{'。' + '\n' + pageData.spineSuggestion}}
                    </p>
                    <p class="resoltInfo" v-if="pageData.spineSuggestion ==null">{{pageData.examineResultPrefix }}
                        <span v-if="pageData.examineResult=='正常'" style="color:#00CCCC;font-size:20px">{{pageData.examineResult}}</span>
                        <span v-if="pageData.examineResult !='正常'" style="color:#FC5555;font-size:20px">{{pageData.examineResult}}</span>
                        {{'。' + '\n' + ''}}
                    </p>
                </div>
                <div class="resoult" v-if="pageData.footResult!=null">
                     <p class="resoltInfo" v-if="pageData.footSuggestion!=null">{{pageData.footResultPrefix }}
                        <span v-if="pageData.footResult=='正常'" style="color:#00CCCC;font-size:20px">{{pageData.footResult}}</span>
                        <span v-if="pageData.footResult!='正常'" style="color:#FC5555;font-size:20px">{{pageData.footResult}}</span>
                        {{'。' + '\n' + pageData.footSuggestion}}
                     </p>
                     <p class="resoltInfo" v-if="pageData.footSuggestion ==null">{{pageData.footResultPrefix }}
                        <span v-if="pageData.footResult=='正常'" style="color:#00CCCC;font-size:20px">{{pageData.footResult}}</span>
                        <span v-if="pageData.footResult!='正常'" style="color:#FC5555;font-size:20px">{{pageData.footResult}}</span>
                        {{'。' + '\n' +''}}
                     </p>
                </div>

                <div class="resoltFooter">
                    <p class="tip">采集编号：{{pageData.examineNo}}</p>
                    <p class="tip">机构信息：{{pageData.organicInfo}}</p>
                    <p class="tip">检测设备：{{pageData.machineInfo}}</p>
                    <p class="tip">免责声明：因不良站姿等干扰因素可能存在误差，有疑问建议至专业机构进行复查。</p>
                </div>
            </div>
            <!-- 初筛版的报告 -->
            <div class="evaluationBox" v-if="pageData.reportType=='小程序脊柱初筛'">
                <span class="blueTitle" style="margin-left:12px;">体态评估结果</span>
                <div class="resoult" v-if="pageData.examineResult!=null">
                    <p class="resoltInfo">
                        <span v-if="pageData.examineResult=='正常'" style="color:#00CCCC;font-size:20px">{{pageData.examineResult}}</span>
                        <span v-if="pageData.examineResult !='正常'" style="color:#FC5555;font-size:20px">{{pageData.examineResult}}</span>
                    </p>
                </div>

                    <div class="imgb" v-if="pageData.backHalfImage!=null">
                        <viewer :images="[pageData.backHalfImage]">
                            <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                                <img alt="" class="imgs" :src="pageData.backHalfImage">
                            </el-tooltip>
                        </viewer>
                        <span class="title">背部站立位半身照</span>
                    </div>
            </div>

        </div>
    </div>
</template>
<script>
import {getReportInfo,getBaseInfo} from '@/api/userManage.js'
import {getProReport} from '@/api/checkTaskDetail.js'
import 'viewerjs/dist/viewer.css'
export default {
    beforeRouteLeave(to,from,next){
        if(to.name === 'SigleTaskList'){
            to.meta.keepAlive = true;
        }
        next();
    },
    data(){
        return{
            id:null,
            pageData:null,
            infoForm:{},
            topData:null,//表头数据
        }
    },

    created(){
        if(this.$route.query){
            if(this.$route.query.dataId){
                 this.id = this.$route.query.dataId;
                this.newData(this.$route.query.dataId);// getDats
            }
            if(this.$route.query.parid){
                this.getTopData(this.$route.query.parid);
            }
             
        };

    },
    methods:{
        //面包屑事件
        back1(){
            this.$router.go(-1);
        },
        back2(){
            this.$router.go(-2);
        },
        back3(){
            this.$router.go(-3);
        },
        back4(){
            this.$router.go(-4);
        },
        //表头数据
        getTopData(id){
                getBaseInfo({
                    id:id
            }).then(res=>{
                if(res.data.status == 200){
                    this.topData=res.data.data;
                }else{
                    this.$message.error(res.data.message);
                }
                    
            }).catch(res=>{
                    this.$message.error(res);
            })
         },
        //getData
        newData(id){
         getReportInfo(
             {id:id}
             ).then((res)=>{
                 if(res.data.status == 200){
                    this.pageData= res.data.data;
                 }else{
                     this.$message.error(res.data.message);
                 }
             }).catch(res=>{
                  this.$message.error(res);
             })
        },
        //下载个人报告
        downloadReport(){
            const loading = this.$loading({
            lock: true,
            text: '下载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
            });
            getProReport({
                id:this.id
            }).then(res => {
                //console.log("res",res)
                if (!res || !res.data) {
                    this.$message.error('暂无');
                 }
                if (res.data.type === 'application/json') {
                        //非进制流文件 在这里
                        const reader = new FileReader()
                        reader.readAsText(res.data)
                        reader.onload = e => {
                        const resData = JSON.parse(reader.result)
                        if(resData.status==200){
                           // 200时res.data.type 就不等于 'application/json'了，就是进制流文件，所以一般不经过这里
                        }
                        if(resData.status!=200){
                            loading.close();
                            this.$message.error(resData.message)
                        }
                    }
                 }
                 else{
                    //进制流文件 开始下载
                    const { data, headers } = res
                    const newHeader = decodeURI(headers['content-disposition']);//进行解码  decodeURI()方式解码
                    const fileName = newHeader.split(';')[1].split('=')[1];//提取文件名称
                    const blob = new Blob([data], { type:'application/zip'})
                    let dom = document.createElement('a')
                    let url = window.URL.createObjectURL(blob)
                    dom.href = url
                    dom.download = decodeURI(fileName)
                    dom.style.display = 'none'
                    document.body.appendChild(dom)
                    dom.click();
                    dom.parentNode.removeChild(dom)
                    window.URL.revokeObjectURL(url)
                    loading.close();
                 }  

            }).catch((err) => {
                console.log("catch-erro",err)
            });    
        },
        deletee(){
            this.$message.error("暂不支持");
        }

    }
}
</script>
<style scoped lang="stylus">
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
.blueTitle
  font-size: 16px 
  color: #353535
  line-height: 28px 
  font-weight: bold 
  position: relative
  &::before
    width: 3px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:1px
    background-color: #00CCCC
.tdStyle
    font-size: 14px 
    color: #858585
    text-align: center
.tip
    font-family: Source Han Sans CN
    font-size: 12px
    color: #858585
    line-height:28px 
.dv
    font-family:SourceHanSansCN-Regular 
    font-size: 14px
    color: #00CCCC
    line-height: 28px 
.imgb
    text-align: center
    display: flex
    flex-direction: column
    align-items: center
    width: 3.83rem
.imgs 
    width: 3.83rem
    height: 4.45rem
    object-fit: cover;
.title 
    font-size: 16px 
    font-family: Source Han Sans CN
    font-weight: Medium
    color: #353535
    line-height: 28px

// nomal style
.detail
    overflow: hidden
    .detailBox
        overflow hidden
        margin-top: 16px
        .topBoxdb
            width: 100%
            height: 296px  
            background-color: #FFFFFF
            box-sizing: border-box
            padding: 20px
            .downBox
                display: flex
                .down
                    width: 116px
                    height: 32px
                    cursor pointer
                    background-color: #00CCCC
                    border-radius: 2px 
                    font-size: 14px 
                    line-height: 32px 
                    text-align: center
                    color: #fff
            .infoBox
                margin-top: 60px
                overflow hidden
                .information
                    margin-top: 40px  
        .imgBox
            width: 100% 
            margin-top: 24px  
            background-color: #FFFFFF
            box-sizing: border-box
            padding: 20px 
            .centerImg
                display: flex
                width: 100%  
                margin-top: 24px
                justify-content: space-around
                
            .dataResoult
                width: 100% 
                margin-top: 24px  
                background-color: #FFFFFF

        /*足部数据*/
        .footData
            width: 100%
            margin-top: 26px 
            background-color: #FFFFFF
            box-sizing: border-box
            
            display: flex
            justify-content: space-between
            .footLeft
                width: 60%
                margin-top: 20px
                margin-left: 20px
                margin-bottom: 20px
                .footImage
                    width: 688px
                    height: 434px
                   
                    margin-top: 22px
            .footRight
                margin-left: 20px  
                margin-top: 20px
                margin-bottom: 20px
                width: 40%
                .footResult
                    display: flex
                    width: 100%
                    margin-top: 22px


        /*底部数据*/
        .evaluationBox
            width: 100% 
            margin-top: 24px  
            box-sizing: border-box
            background-color: #FFFFFF
            padding: 20px 
            .resoult
                width: 100%
                border: 1px solid #AEAEAE
                margin-top: 24px
                margin-bottom: 24px
                .resoltInfo
                    margin-left: 40px
                    font-family:SourceHanSansCN-Regular 
                    font-size: 14px
                    color: #353535
                    line-height: 28px
                    white-space: pre-line;
                .resoltFooter
                    width: 80%
                    margin-top: 120px 
                    margin-left: 40px  
               

                    
                 
                

</style>